import $ from 'jquery';
import Cookies from 'js-cookie';
import Plyr from 'plyr';
import axios from 'axios';
import anime from 'animejs/lib/anime.es.js';

document.addEventListener('DOMContentLoaded', async () => {
    if (!document.querySelector('body').classList.contains('shopUebersicht')) {
        try {
            // Load CSRF cookie
            const response = await axios.get('/api/getcsrf');

            // Get CSRF cookie as JSON and save it as global variable
            window.csrfTokenName = response.data.csrfTokenName;
            window.csrfTokenValue = response.data.csrfTokenValue;
        } catch (error) {
            console.log('An error has occurred when retrieving the CSRF token: ', error);
        }
    }
});

document.addEventListener('DOMContentLoaded', () => {
    // Cookie consent banner
    const cookieConsent = document.querySelector('#cookie-consent');
    if (cookieConsent) {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: cookieConsent.dataset.popupBackground,
                    text: cookieConsent.dataset.popupText,
                    link: cookieConsent.dataset.popupLink,
                },
                button: {
                    background: cookieConsent.dataset.popupBackground,
                    text: cookieConsent.dataset.popupText,
                },
            },
            content: {
                message: cookieConsent.dataset.message,
                href: cookieConsent.dataset.href,
                link: cookieConsent.dataset.link,
                dismiss: cookieConsent.dataset.dismiss,
                target: '_blank',
            },
            theme: 'block',
        });
    }

    // https://css-tricks.com/snippets/jquery/smooth-scrolling/
    // Select all links with hashes
    document.querySelectorAll('a[href*="#"]:not([href="#"]):not([href="#0"])').forEach((link) => {
        link.addEventListener('click', (event) => {
            // On-page links
            const linkUrl = new URL(event.currentTarget.href);
            if (
                window.location.pathname.replace(/^\//, '') === linkUrl.pathname.replace(/^\//, '') &&
                window.location.hostname === linkUrl.hostname
            ) {
                // Figure out element to scroll to
                let target = document.querySelector(linkUrl.hash);
                target = target !== null ? target : document.querySelector(`[name=${linkUrl.hash.slice(1)}]`);
                // Does a scroll target exist?
                if (target) {
                    // Only prevent default if animation is actually going to happen
                    event.preventDefault();

                    anime({
                        targets: [document.body, document.documentElement],
                        scrollTop:
                            window.pageYOffset +
                            target.getBoundingClientRect().top -
                            window.getComputedStyle(document.querySelector('html')).scrollPaddingTop.replace('px', ''),
                        duration: 800,
                        easing: 'easeInOutQuad',
                        complete: () => {
                            // Callback after animation
                            // Must change focus!
                            target.focus();
                            if (target === document.activeElement) {
                                // Checking if the target was focused
                                return false;
                            }
                            target.tabIndex = -1; // Adding tabindex for elements not focusable
                            target.focus(); // Set focus again
                            return true;
                        },
                    });
                }
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    // externe links
    $('#main a').each(function () {
        const a = new RegExp(`/${window.location.host}/`);
        if (!a.test(this.href)) {
            $(this).click(function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, '_blank');
            });
        }
    });

    if (Cookies.get('contrast') == 1) {
        $('body').addClass('contrast');
    } else {
        $('body').removeClass('contrast');
    }

    if (Cookies.get('colors') == 1) {
        $('body').addClass('colors');
    } else {
        $('body').removeClass('colors');
    }

    $('.switchContrast').click(() => {
        if (Cookies.get('contrast') == 1) {
            Cookies.set('contrast', '0');
            $('body').removeClass('contrast');
        } else {
            Cookies.set('contrast', '1');
            $('body').addClass('contrast');
        }
    });

    $('.switchColors').click(() => {
        if (Cookies.get('colors') == 1) {
            Cookies.set('colors', '0');
            $('body').removeClass('colors');
        } else {
            Cookies.set('colors', '1');
            $('body').addClass('colors');
        }
    });

    if (Cookies.get('fontsize')) {
        const size = Cookies.get('fontsize');
        $('html').css('font-size', `${size}%`);
    }

    $('.switchSize').click(() => {
        if (Cookies.get('fontsize')) {
            var size = Cookies.get('fontsize');
            size = size * 1 + 10;

            if (size > 150) {
                size = 100;
            }
            Cookies.set('fontsize', size);
            $('html').css('font-size', `${size}%`);

            // $("body").css("line-height", size + "%");
        } else {
            var size = 110;
            Cookies.set('fontsize', size);
            $('html').css('font-size', `${size}%`);

            // $("body").css("line-height", size + "%");
        }
    });

    if ($('.container-subnavigation .nav').length) {
        // $('.container-subnavigation .nav a').attr('href', function (_, oldHref) {
        //     return oldHref + "#nav";
        // });
    } else if ($('body').hasClass('homepage')) {
    } else {
        $('.container-subnavigation').addClass('container-subnavigation-half');
    }

    $('.removeLineItemButton').click(function () {
        const id = $(this).data('line-item-id');
        $(`.checkbox-${id}`).prop('checked', true);
        $('#formcart').submit();
    });

    $(window).scroll(function () {
        if ($('body').hasClass('no-scrolled')) {
        } else if (window.matchMedia('(min-width: 1024px)').matches) {
            $('body').toggleClass('scrolled', $(this).scrollTop() > 109);
        } else if (window.matchMedia('(min-width: 750px)').matches) {
            $('body').toggleClass('scrolled', $(this).scrollTop() > 76);
        } else {
            $('body').toggleClass('scrolled', $(this).scrollTop() > 50);
        }
    });

    Fancybox.bind("[data-fancybox=\"gallery\"]", {
        afterLoad(instance, current) {
            current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
        },
    });

    /*
    $('[data-fancybox="gallery"]').fancybox({
        afterLoad(instance, current) {
            current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
        },
    });
    */
    
    $('.slider').slick({
        accessibility: true,
        arrows: true,
        dots: false,
        infinite: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
    });

    $('.container-headerslider .wrapper-slider').slick({
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    // navigation schliessen wenn wir ausserhalb tabben
    function PressTab(e) {
        if ($('#navigation').hasClass('show')) {
            const keycode = window.event ? event.keyCode : e.keyCode;
            if (keycode == 9) {
                if (
                    $(document.activeElement).hasClass('nav-item-language-switch') ||
                    $(document.activeElement).hasClass('nav-item-link-1') ||
                    $(document.activeElement).hasClass('nav-item-link-2')
                ) {
                    // $('#navigation').removeClass('show');
                } else {
                    $('#navigation').removeClass('show');
                }
            }
        }
    }

    document.onkeyup = PressTab;

    var lang = $('html').attr('lang');
    var lang = lang.substring(2, 0);

    // const player = new Plyr('.plyr-player', {
    //     youtube: { cc_load_policy: 1, cc_lang_pref: lang },
    //     captions: { active: true, language: lang }
    // });

    const players = Array.from(document.querySelectorAll('.plyr-player'));

    players.map(
        (player) =>
            new Plyr(player, {
                autoplay: false,
                youtube: { cc_load_policy: 1, cc_lang_pref: lang },
                captions: { active: true, language: lang },
            })
    );

    if (lang != 'de') {
        if ($('#formkontaktform').length > 0) {
            if (lang == 'fr') {
                var email = 'Email';
                var vorname = 'Prénom';
                var nachname = 'Nom';
                var telefonnummer = 'Téléphone';
                var bemerkungen = 'Message';
                var senden = 'Envoyer';
                var pflichtfelder = '* champs obligatoires';
            } else if (lang == 'it') {
                var email = 'Email';
                var vorname = 'Nome';
                var nachname = 'Cognome';
                var telefonnummer = 'Telefono';
                var bemerkungen = 'Messagio';
                var senden = 'Inviare';
                var pflichtfelder = '* campi obbligatori';
            } else {
                var email = 'Email';
                var vorname = 'First name';
                var nachname = 'Name';
                var telefonnummer = 'Telephone';
                var bemerkungen = 'Message';
                var senden = 'Send';
                var pflichtfelder = '* mandatory fields';
            }

            $('label[for="form-input-email"]').text(email);
            $('label[for="form-input-vorname"]').text(vorname);
            $('label[for="form-input-nachname"]').text(nachname);
            $('label[for="form-input-telefonnummer"]').text(telefonnummer);
            $('label[for="form-input-bemerkungen"]').text(bemerkungen);
            $('.submitbutton').text(senden);
            $('.pflichtfelder').text(pflichtfelder);
        }
    }

    $('.suche .searchform .search-input').autocomplete({
        serviceUrl: `/api/search?results=8${$('.suche .searchform .search-input').val()}`,
        minChars: 3, // min. 3 characters
        deferRequestBy: 250, // min 250ms delay
        // width: 340, // width in px
        onSelect(suggestion) {
            window.location.href = unescape(suggestion.data);
        },
    });

    if (window.location.hash) {
        let target = $(window.location.hash);
        if (target.length == 0) target = $(`a[name="${this.hash.substr(1)}"]`);
        if (target.length == 0) target = $('html');

        $('body').addClass('scrolled');

        $('html, body').animate({ scrollTop: target.offset().top - 50 }, 0);
        // return false;
    }

    // Back to top
    $(window).scroll(function () {
        $('.totop').toggleClass('active', $(this).scrollTop() > 150);
    });

    $('.totop').click(() => {
        $('body,html').animate({ scrollTop: 0 }, 300);
    });

    $('.navbar-toggler-mobile-burger').click(() => {
        $('.navbar-toggler-mobile-suche').addClass('collapsed');
        $('.navbar-collapse.suche').removeClass('show');
    });
    $('.navbar-toggler-mobile-suche').click(() => {
        $('.navbar-toggler-mobile-burger').addClass('collapsed');
        $('.navbar-collapse.navigation').removeClass('show');
    });

    $('.navbar-toggler-tablet-burger').click(() => {
        $('.wrapper-navbar-toggler-tablet-burger').toggleClass('active');
        $('.navbar-toggler-tablet-suche').addClass('collapsed');
        $('.navbar-collapse.suche').removeClass('show');
    });
    $('.navbar-toggler-tablet-suche').click(() => {
        $('.wrapper-navbar-toggler-tablet-burger').removeClass('active');
        $('.navbar-toggler-tablet-burger').addClass('collapsed');
        $('.navbar-collapse.navigation').removeClass('show');
    });

    $('.menubottondesktop1').click(function () {
        $('body').removeClass('scrolled');
        $(this).removeClass('active');
    });

    $('.menubottondesktop2').click(function () {
        $('body').removeClass('scrolled');
        $(this).removeClass('active');
    });

    $('.menubottondesktop2 svg').click(function () {
        $('body').removeClass('scrolled');
        $(this).removeClass('active');
    });

    $('#row-desktop-header-main-navigation .main-navigation-header .nav-item-link-1').click(function () {
        if (!$(this).hasClass('shop-link') && !$(this).hasClass('back-link')) {
            if ($(this).hasClass('active')) {
                $('#row-desktop-header-main-navigation .main-navigation-header .nav-item-link-1').removeClass('active');
                // $('body').removeClass('overflow-hidden');
                $('.container-headerimage-hide').hide();

                $('.premain').removeClass('active');
                $('main').removeClass('fixed');
                $('footer').removeClass('fixed');
                $('body').removeClass('no-scrolled');
            } else {
                $('#row-desktop-header-main-navigation .main-navigation-header .nav-item-link-1').removeClass('active');
                $(this).addClass('active');
                // $('body').addClass('overflow-hidden');
                $('.container-headerimage-hide').show();

                $('main').addClass('fixed');
                $('footer').addClass('fixed');
                $('body').addClass('no-scrolled');
            }
        }
    });

    if (window.matchMedia('(max-width: 991px)').matches) {
        $('#accordionNavigation .nav-item-link-1').click(function () {
            if ($(this).hasClass('collapsed')) {
                // alle anderen zuklappen
                $('#accordionNavigation .nav-item-link-1').addClass('collapsed');
                $('#accordionNavigation .nav-item-link-1 + .collapse').removeClass('show');
                $(this).removeClass('collapsed');
            } else {
            }
        });
    }

    // Cache the highest
    let lastHigh = 0;

    if (window.matchMedia('(min-width: 768px)').matches) {
        // Select and loop the elements you want to equalise
        $('.row.row-video .video-description', this).each(function () {
            // 2 spalten
            if (window.matchMedia('(max-width: 991px)').matches) {
                var id = $(this).data('id');
                if (id % 2 == 0) {
                    if (lastHigh > 0 && lastHigh < $(this).height()) {
                        var id = $(this).data('id');
                        const idNeu = id - 1;
                        $(`.video-description-${idNeu}`).css('height', $(this).height());
                    } else if (lastHigh > 0 && lastHigh > $(this).height()) {
                        $(`.video-description-${id}`).css('height', lastHigh);
                    }
                }
                lastHigh = $(this).height();
            }
            // 3 spalten
            else {
                var id = $(this).data('id');
                // 2. spalte
                if (id % 3 == 2) {
                    // 1 höher
                    if (lastHigh > 0 && lastHigh > $(this).height()) {
                        $(`.video-description-${id}`).css('height', lastHigh);
                        $(`.video-description-${id + 1}`).css('height', lastHigh);
                    } else if (lastHigh > 0 && lastHigh < $(this).height()) {
                        $(`.video-description-${id - 1}`).css('height', $(this).height());
                        $(`.video-description-${id + 1}`).css('height', $(this).height());
                    }
                }
                // 3. spalte
                else if (id % 3 == 0) {
                    if (lastHigh > 0 && lastHigh < $(this).height()) {
                        $(`.video-description-${id - 2}`).css('height', $(this).height());
                        $(`.video-description-${id - 1}`).css('height', $(this).height());
                    } else if (lastHigh > 0 && lastHigh > $(this).height()) {
                        $(`.video-description-${id}`).css('height', lastHigh);
                    }
                }

                lastHigh = $(this).height();
            }
        });
    }

    function trauerspende() {
        if ($('#trauerspende').is(':checked')) {
            $('.wrapper-trauerspende-inner').removeClass('d-none');
            $("#txtTrauerfall").prop('required',true);
            $("#txtTraueradresse").prop('required',true);
        } else {
            $('.wrapper-trauerspende-inner').addClass('d-none');
            $('#txtTrauerfall').val('');
            $("#txtTrauerfall").prop('required',false);
            $('#txtTraueradresse').val('');
            $("#txtTraueradresse").prop('required',false);
        }
    }

    $('.wrapper-trauerspende').click(function () {
        trauerspende();
    });
    trauerspende();

    // $('.menubottondesktop1').click(function () {
    //     $('body').removeClass('scrolled');
    //     $(this).removeClass('active');
    // });

    // $('.menubottondesktop2').click(function () {
    //     $('body').removeClass('scrolled');
    //     $(this).removeClass('active');
    // });

    // $('.menubottondesktop2 svg').click(function () {
    //     $('body').removeClass('scrolled');
    //     $(this).removeClass('active');
    // });
});
