// Importing scripts
import $ from 'jquery';
import 'bootstrap';
import 'devbridge-autocomplete';
import 'cookieconsent';
//import '@fancyapps/fancybox';
//import { Fancybox } from "@fancyapps/ui";

import 'slick-carousel/slick/slick.min'; // Has to be the minified version. Otherwise, it won't work.
import './js/scripts';

// Importing styles (bootstrap + customization)
import './scss/app.scss';

// https://symfonycasts.com/screencast/javascript-webpack/global-jquery
// expose $ globally, so I can use it in the template
// ... even though I should put all my code here!
global.$ = $;
global.jQuery = $;

// Accept HMR
// if (module && module.hot) {
//     module.hot.accept();
// }
